import request from '@/utils/request'

// 查询星河村-动态文章列表
export function listArticle(query) {
  return request({
    url: '/community/article/list',
    method: 'get',
    params: query
  })
}

// 查询星河村-动态文章详细
export function getArticle(id) {
  return request({
    url: '/community/article/' + id,
    method: 'get'
  })
}

// 新增星河村-动态文章
export function addArticle(data) {
  return request({
    url: '/community/article',
    method: 'post',
    data: data
  })
}

// 修改星河村-动态文章
export function updateArticle(data) {
  return request({
    url: '/community/article',
    method: 'put',
    data: data
  })
}

// 删除星河村-动态文章
export function delArticle(id) {
  return request({
    url: '/community/article/' + id,
    method: 'delete'
  })
}

// 获取剧评的多个维度(前三个为默认维度)
export function getScriptReviewDimension() {
  return request({
    url: '/community/article/script/review/dimension',
    method: 'get'
  })
}
